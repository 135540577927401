/* eslint-disable react/jsx-fragments */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Text, Heading } from 'theme-ui';
import { useEffect, Fragment } from 'react';
import { CenterXY } from 'components/utility-wrappers/center';
import SEO from 'components/utility-wrappers/seo';
import { changeBgColor } from 'utils/helpers';

export default function NotFound(props) {
  useEffect(() => {
    changeBgColor(props?.color);
  }, [props?.color]);

  return (
    <Fragment>
      <SEO
        title="Not Found"
        meta={[{ name: 'robots', content: 'noindex, nofollow' }]}
      />
      <CenterXY>
        <Box
          data-scroll-section
          sx={{ p: 2, width: '100%', maxWidth: '70rem' }}
        >
          <Heading variant="h1" as="h1" sx={{ marginBottom: '3rem' }}>
            The contents of this page seems to have wandered off.
          </Heading>
          <Text as="p" variant="body">
            You know, like the paintings in Harry Potter.
          </Text>
        </Box>
      </CenterXY>
    </Fragment>
  );
}
