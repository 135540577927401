import React from 'react';
import styles from './center.module.scss';

// These components are used to center children in the X, Y or XY axis
// <Center xy>
//   <div>lorem ipsum.</div>
// </Center>;

export default function Center(props) {
  const { x, y, xy } = props;
  let cssString = ``;
  if (x) {
    cssString += `${styles.centerX} `;
  }
  if (y) {
    cssString += `${styles.centerY} `;
  }
  if (xy) {
    cssString += `${styles.centerXY} `;
  }

  const centeredChildren = React.Children.map(props.children, (child) =>
    React.cloneElement(child, {
      className: `${cssString ? cssString : ''}${
        child.props.className ? child.props.className : ''
      }`,
    }),
  );

  return centeredChildren;
}

export function CenterX(props) {
  const centeredChildren = React.Children.map(props.children, (child) =>
    React.cloneElement(child, {
      className: `${child.props.className ? child.props.className + ' ' : ''}${
        styles.centerX
      }`,
    }),
  );

  return centeredChildren;
}

export function CenterY(props) {
  const centeredChildren = React.Children.map(props.children, (child) =>
    React.cloneElement(child, {
      className: `${child.props.className ? child.props.className + ' ' : ''}${
        styles.centerY
      }`,
    }),
  );

  return centeredChildren;
}

export function CenterXY(props) {
  const centeredChildren = React.Children.map(props.children, (child) =>
    React.cloneElement(child, {
      className: `${child.props.className ? child.props.className + ' ' : ''}${
        styles.centerXY
      }`,
    }),
  );

  return centeredChildren;
}
